<template>
<main id="main" class="contact-used">
  <div class="wrap1160">
    <ContactForm v-if="editFlag"/>
    <Completion v-else-if="completedFlag"/>
    <ComfirmForm v-else/>
  </div>
</main>
</template>

<script>
import useContact from '@/stores/useContact'
import {defineComponent} from '@vue/composition-api'
import ComfirmForm from '../../components/Contact/Comfirm'
import Completion from '../../components/Contact/Completion'
import ContactForm from '../../components/Contact/Form'
export default defineComponent({
  setup() {
    const contactStore = useContact()
    return {
      contactStore
    }
  },
  components : {
    ContactForm,
    ComfirmForm,
    Completion
  },
  data() {
    return {
    }
  },
  computed : {
    editFlag() {
      return this.contactStore.editFlag
    },
    completedFlag() {
      return this.contactStore.completedFlag
    },
  },
  methods : {}
})
</script>

<style scoped lang="scss" src="@/assets/scss/style.scss"></style>
